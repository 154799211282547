import React from "react";
import { PropTypes } from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import LoggedUser from "../../commons/LoggedUser";
import TimecardHoursReadonly from "./TimecardHoursReadonly";
import TimecardHours from "./TimecardHours";
import BillingHoursReadonly from "../billing/BillingHoursReadonly";
import Paper from "@mui/material/Paper";
import TaxableExpensesReadonly from "./TaxableExpensesReadonly";
import TaxableExpenses from "./TaxableExpenses";
import TimecardFile from "./TimecardFile";
import TotalSummaryDetail from "./TotalSummaryDetail";
import FacilityReportFile from "../timecards/FacilityReportFile";
import { TimesheetStatus, TimesheetFileTypes } from "../../commons/Enums";
import NoteTypeResolver from "../notes/NoteTypeResolver";
import BillingHours from "../billing/BillingHours";
import TimesheetApi from "../../api/TimesheetApi";
import ExpensesPanel from "./ExpensesPanel";
import { useFeatureFlags } from "../../commons/Features";
import NonTaxableExpensesPanel from "./NonTaxableExpensesPanel";
import NonTaxableExpenses from "./NonTaxableExpenses";
import { usePermissions, useTimesheetTemplate } from "hooks";
import TimecardDidNotWork from "components/timecards/TimecardDidNotWork";
import TimecardNotes from "components/notes/TimecardNotes";
import { isExceptionState } from "types/constants";
import CallOffsSection from "./CallOffsSection";
import CallOffsSectionReadOnly from "./CallOffsSectionReadOnly";

const TimecardSummary = (props) => {
  const {
    assignment,
    onNotesRetrieved,
    onFacilityReportFileUploaded,
    onFacilityReportFilesAdded,
    onNoteChange,
    onNotifyTravelerChange,
    onTimecardEntriesChange,
    onTaxableExpensesChange,
    onBonusExpensesChange,
    onNonTaxableExpensesChange,
    onBillingEntriesChange,
    onTimecardAdjustmentEntriesChange,
    onAddAdjustment,
    onRemoveAdjustment,
    previouslyLocked,
    onDidNotWorkReset,
    onAddNonTaxableAdjustment,
    onRemoveNonTaxableAdjustment,
    onNonTaxableAdjustmentsChange,
    onHandleOnExpenseChange,
    onCallOffsChange,
  } = props;

  const { missedShiftDeductVisible } = useFeatureFlags();
  const { canUpdateFacilityReportFileInApprovedStatus } = usePermissions();

  const { showTimesheet, handlePanelChange, showNotes, isPayrollExpanded, isNoTimecardStatus } =
    useTimesheetTemplate(assignment);

  const isSalesRep = LoggedUser.isSalesRep();
  const isSuperAdmin = LoggedUser.isSuperAdmin();

  const canUploadFacilityReport =
    (assignment.status === TimesheetStatus.DRAFT ||
      assignment.status === TimesheetStatus.FOR_REVIEW ||
      assignment.status === TimesheetStatus.BILLING ||
      (assignment.status === TimesheetStatus.APPROVED &&
        canUpdateFacilityReportFileInApprovedStatus)) &&
    !isSalesRep &&
    !((assignment.isLate || assignment.isAdjustment) && previouslyLocked);

  const billingHoursComponent =
    isSuperAdmin &&
    !assignment.uploadedToNetSuite &&
    !((assignment.isLate || assignment.isAdjustment) && previouslyLocked) ? (
      <BillingHours
        rows={assignment.billingEntries}
        isFirstWeek={assignment.isFirstWeek}
        onBillingEntriesChange={(entries) => onBillingEntriesChange(entries)}
      />
    ) : (
      <BillingHoursReadonly rows={assignment.billingEntries} isFirstWeek={assignment.isFirstWeek} />
    );
  const getNotes = () => {
    if (!assignment.notes) {
      TimesheetApi.getNotesByAssigment(assignment.id).then((data) => {
        onNotesRetrieved(data);
      });
    }
    return assignment.notes;
  };

  const showCallOffsSection = !assignment.isExpenseOnly && !assignment.isAdjustment;
  const isExpenseOnly = assignment.isExpenseOnly && !assignment.isAdjustment;

  return (
    <div className="timecard-readonly">
      {isNoTimecardStatus && (
        <Paper className="timecard-readonly-paper">
          <TimecardDidNotWork
            timesheet={assignment}
            resetCallback={onDidNotWorkReset}></TimecardDidNotWork>
        </Paper>
      )}

      {assignment.status === TimesheetStatus.LATE && (
        <Paper className="timecard-readonly-paper">
          <div>This traveler had a late timecard</div>
        </Paper>
      )}

      {!isNoTimecardStatus && assignment.status !== TimesheetStatus.LATE && showTimesheet && (
        <>
          {assignment.status !== TimesheetStatus.APPROVED && (
            <>
              <TimecardHoursReadonly
                entries={assignment.timecardEntries}
                adjustments={assignment.timecardAdjustmentTypes}
                invoiceDelivery={
                  assignment.billingEntries != null
                    ? assignment.billingEntries.filter((x) => x.tableOrder === 0)[0].textFieldValue
                    : ""
                }
                missedLunchHours={assignment.missedLunchHours}
                isDigitalTimecardEntry={assignment.isDigitalTimecardEntry}
                isExceptionState={
                  isExceptionState(assignment.clientState) &&
                  assignment.status !== TimesheetStatus.BILLING
                }
                hasToAcknowledgeRateChange={assignment.hasToAcknowledgeRateChange}
                onRateChangesAcknowledged={props.onRateChangesAcknowledged}
                timesheetId={assignment.id}
              />
              {missedShiftDeductVisible ? (
                <NonTaxableExpensesPanel
                  legend={"Non Taxable"}
                  jobAssignment={assignment}></NonTaxableExpensesPanel>
              ) : (
                <TaxableExpensesReadonly
                  expenses={assignment.nonTaxableExpenses}
                  legend={"Non Taxable"}
                  isFirstWeek={assignment.isFirstWeek}
                  isLastWeek={assignment.isLastWeek}
                />
              )}
              <TaxableExpensesReadonly
                expenses={assignment.taxableExpenses}
                legend={"Taxable"}
                isFirstWeek={assignment.isFirstWeek}
                isLastWeek={assignment.isLastWeek}
              />
              {assignment.expensesInJobAssigment &&
                assignment.expensesInJobAssigment.length > 0 && (
                  <ExpensesPanel expenses={assignment.expensesInJobAssigment} legend={"Expenses"} />
                )}
              {assignment.hasBonusSection && (
                <>
                  <TaxableExpensesReadonly expenses={assignment.bonusExpenses} legend={"Bonus"} />
                </>
              )}

              {showCallOffsSection && (
                <CallOffsSectionReadOnly
                  callOffs={assignment.callOffEntries}
                  isDigitalTimecardEntry={assignment.isDigitalTimecardEntry}
                />
              )}

              <TotalSummaryDetail
                nonTaxableExpenses={assignment.nonTaxableExpenses}
                taxableExpenses={assignment.taxableExpenses}
                bonusExpenses={assignment.bonusExpenses}
                timecardEntries={assignment.timecardEntries}
                adjustments={assignment.timecardAdjustmentTypes}
                expenses={assignment.expensesInJobAssigment}
                nonTaxableAdjustments={assignment.nonTaxableAdjustmentTypes}
                legend={"Grand Total"}></TotalSummaryDetail>
            </>
          )}

          {assignment.status === TimesheetStatus.APPROVED && (
            <>
              {isSuperAdmin &&
                !assignment.uploadedToNetSuite &&
                !((assignment.isLate || assignment.isAdjustment) && previouslyLocked) && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography>Payroll Timecard</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                      {!isExpenseOnly && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Timecard</Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ flexDirection: "column" }}>
                            <TimecardHours
                              onTimecardEntriesChange={(entries) =>
                                onTimecardEntriesChange(entries)
                              }
                              types={assignment.timecardEntries}
                              adjustments={assignment.timecardAdjustmentTypes}
                              onAddAdjustment={(name) => onAddAdjustment(name)}
                              onRemoveAdjustment={(name, order) => onRemoveAdjustment(name, order)}
                              onTimecardAdjustmentEntriesChange={(entries) =>
                                onTimecardAdjustmentEntriesChange(entries)
                              }
                              key={assignment.id}
                              status={assignment.status}
                              invoiceDelivery={
                                assignment.billingEntries != null
                                  ? assignment.billingEntries.filter((x) => x.tableOrder === 0)[0]
                                      .textFieldValue
                                  : ""
                              }
                              missedLunchHours={assignment.missedLunchHours}
                              isDigitalTimecardEntry={assignment.isDigitalTimecardEntry}
                              clientState={assignment.clientState}
                              timesheetId={assignment.id}
                              hasToAcknowledgeRateChange={assignment.hasToAcknowledgeRateChange}
                              onRateChangesAcknowledged={props.onRateChangesAcknowledged}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}

                      {!isExpenseOnly && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Non Taxable</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {missedShiftDeductVisible ? (
                              <NonTaxableExpenses
                                onChange={(entries) => onNonTaxableExpensesChange(entries)}
                                expenses={assignment.nonTaxableExpenses}
                                isFirstWeek={assignment.isFirstWeek}
                                isLastWeek={assignment.isLastWeek}
                                isDigitalTimecardEntry={assignment.isDigitalTimecardEntry}
                                nonTaxableAdjustments={assignment.nonTaxableAdjustmentTypes}
                                onAddAdjustment={(name) => onAddNonTaxableAdjustment(name)}
                                onRemoveAdjustment={(name, order) =>
                                  onRemoveNonTaxableAdjustment(name, order)
                                }
                                onNonTaxableAdjustmentEntriesChange={(entries) =>
                                  onNonTaxableAdjustmentsChange(entries)
                                }
                                handleOnExpenseChange={(name, newAmount) =>
                                  onHandleOnExpenseChange(name, newAmount)
                                }></NonTaxableExpenses>
                            ) : (
                              <TaxableExpenses
                                onChange={(entries) => onNonTaxableExpensesChange(entries)}
                                expenses={assignment.nonTaxableExpenses}
                                key={assignment.id}
                                isFirstWeek={assignment.isFirstWeek}
                                isLastWeek={assignment.isLastWeek}
                              />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}

                      {!isExpenseOnly && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Taxable</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TaxableExpenses
                              onChange={(entries) => onTaxableExpensesChange(entries)}
                              expenses={assignment.taxableExpenses}
                              key={assignment.id}
                              isFirstWeek={assignment.isFirstWeek}
                              isLastWeek={assignment.isLastWeek}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}

                      {assignment.expensesInJobAssigment &&
                        assignment.expensesInJobAssigment.length > 0 && (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header">
                              <Typography>Expenses</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ExpensesPanel expenses={assignment.expensesInJobAssigment} />
                            </AccordionDetails>
                          </Accordion>
                        )}
                      {assignment.hasBonusSection && !isExpenseOnly && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Bonus</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TaxableExpenses
                              onChange={(entries) => onBonusExpensesChange(entries)}
                              expenses={assignment.bonusExpenses}
                              key={assignment.id}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}

                      {showCallOffsSection && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Call Offs</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CallOffsSection
                              callOffs={assignment.callOffEntries}
                              onChange={(entries) => onCallOffsChange(entries)}
                              isDigitalTimecardEntry={assignment.isDigitalTimecardEntry}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}

                      <TotalSummaryDetail
                        nonTaxableExpenses={assignment.nonTaxableExpenses}
                        taxableExpenses={assignment.taxableExpenses}
                        bonusExpenses={assignment.bonusExpenses}
                        timecardEntries={assignment.timecardEntries}
                        adjustments={assignment.timecardAdjustmentTypes}
                        expenses={assignment.expensesInJobAssigment}
                        nonTaxableAdjustments={assignment.nonTaxableAdjustmentTypes}
                        legend={"Grand Total"}></TotalSummaryDetail>
                    </AccordionDetails>
                  </Accordion>
                )}
              {(!isSuperAdmin ||
                assignment.uploadedToNetSuite ||
                ((assignment.isLate || assignment.isAdjustment) && previouslyLocked)) && (
                <Accordion expanded={isPayrollExpanded} onChange={handlePanelChange("payroll")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography>Payroll Timecard</Typography>
                  </AccordionSummary>

                  <AccordionDetails style={{ flexDirection: "column" }}>
                    {!isExpenseOnly && (
                      <TimecardHoursReadonly
                        entries={assignment.timecardEntries}
                        adjustments={assignment.timecardAdjustmentTypes}
                        invoiceDelivery={
                          assignment.billingEntries != null
                            ? assignment.billingEntries.filter((x) => x.tableOrder === 0)[0]
                                .textFieldValue
                            : ""
                        }
                        missedLunchHours={assignment.missedLunchHours}
                        isExceptionState={
                          isExceptionState(assignment.clientState) &&
                          assignment.status !== TimesheetStatus.BILLING
                        }
                        hasToAcknowledgeRateChange={assignment.hasToAcknowledgeRateChange}
                        onRateChangesAcknowledged={props.onRateChangesAcknowledged}
                        timesheetId={assignment.id}
                        isDigitalTimecardEntry={assignment.isDigitalTimecardEntry}
                      />
                    )}

                    {!isExpenseOnly && missedShiftDeductVisible
                      ? !isExpenseOnly && (
                          <NonTaxableExpensesPanel
                            legend={"Non Taxable"}
                            jobAssignment={assignment}></NonTaxableExpensesPanel>
                        )
                      : !isExpenseOnly && (
                          <TaxableExpensesReadonly
                            expenses={assignment.nonTaxableExpenses}
                            legend={"Non Taxable"}
                          />
                        )}

                    {!isExpenseOnly && (
                      <TaxableExpensesReadonly
                        expenses={assignment.taxableExpenses}
                        legend={"Taxable"}
                      />
                    )}

                    {assignment.expensesInJobAssigment &&
                      assignment.expensesInJobAssigment.length > 0 && (
                        <ExpensesPanel
                          expenses={assignment.expensesInJobAssigment}
                          legend={"Expenses"}
                        />
                      )}
                    {assignment.hasBonusSection && (
                      <TaxableExpensesReadonly
                        expenses={assignment.bonusExpenses}
                        legend={"Bonus"}
                      />
                    )}

                    {showCallOffsSection && (
                      <CallOffsSectionReadOnly
                        callOffs={assignment.callOffEntries}
                        isDigitalTimecardEntry={assignment.isDigitalTimecardEntry}
                      />
                    )}

                    <TotalSummaryDetail
                      nonTaxableExpenses={assignment.nonTaxableExpenses}
                      taxableExpenses={assignment.taxableExpenses}
                      bonusExpenses={assignment.bonusExpenses}
                      timecardEntries={assignment.timecardEntries}
                      adjustments={assignment.timecardAdjustmentTypes}
                      expenses={assignment.expensesInJobAssigment}
                      nonTaxableAdjustments={assignment.nonTaxableAdjustmentTypes}
                      legend={"Grand Total"}></TotalSummaryDetail>
                  </AccordionDetails>
                </Accordion>
              )}

              {!assignment.isAdjustment && !assignment.isExpenseOnly && (
                <Accordion onChange={handlePanelChange("billing")}>
                  <AccordionSummary
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography>Billing Timecard</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: "column" }}>
                    {billingHoursComponent}
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
        </>
      )}

      {showTimesheet && assignment.status !== TimesheetStatus.LATE && !assignment.isExpenseOnly && (
        <>
          <TimecardFile
            timesheetId={assignment.id}
            timecardUploadId={assignment.timecardUploadId}
            readonly={true}
            isDigitalTimecardEntry={assignment.isDigitalTimecardEntry}
          />
          <FacilityReportFile
            timesheetId={assignment.id}
            facilityReportUploadId={assignment.facilityReportUploadId}
            onFileUploaded={onFacilityReportFileUploaded}
            onFilesAdded={onFacilityReportFilesAdded}
            uploading={assignment.uploading & TimesheetFileTypes.FACILITY_REPORT}
            readonly={!canUploadFacilityReport}
            isDigitalTimecardEntry={assignment.isDigitalTimecardEntry}
          />
        </>
      )}

      {(showNotes || (showTimesheet && assignment.status === TimesheetStatus.DRAFT)) &&
        !isExpenseOnly && (
          <TimecardNotes
            inputAllowed={showNotes}
            notes={getNotes()}
            note={assignment.note}
            noteNotifyTraveler={assignment.notifyTraveler}
            onNoteChange={onNoteChange}
            onNotifyTravelerChange={onNotifyTravelerChange}
            noteTypeFilter={NoteTypeResolver.getNoteTypeFilterFromStatus(assignment.status)}
          />
        )}
    </div>
  );
};

TimecardSummary.propTypes = {
  assignment: PropTypes.object.isRequired,
  onNotesRetrieved: PropTypes.func.isRequired,
  onNoteChange: PropTypes.func.isRequired,
  onNotifyTravelerChange: PropTypes.func.isRequired,
  onFacilityReportFileUploaded: PropTypes.func.isRequired,
  onFacilityReportFilesAdded: PropTypes.func.isRequired,
  featureFlags: PropTypes.array.isRequired,
  onTimecardEntriesChange: PropTypes.func,
  onTaxableExpensesChange: PropTypes.func,
  onBonusExpensesChange: PropTypes.func,
  onNonTaxableExpensesChange: PropTypes.func,
  onBillingEntriesChange: PropTypes.func,
  onTimecardAdjustmentEntriesChange: PropTypes.func,
  onAddAdjustment: PropTypes.func,
  onRemoveAdjustment: PropTypes.func,
  previouslyLocked: PropTypes.bool,
  onDidNotWorkReset: PropTypes.func,
  onAddNonTaxableAdjustment: PropTypes.func,
  onRemoveNonTaxableAdjustment: PropTypes.func,
  onNonTaxableAdjustmentsChange: PropTypes.func,
  onHandleOnExpenseChange: PropTypes.func,
  onRateChangesAcknowledged: PropTypes.func,
  onCallOffsChange: PropTypes.func,
};

export default TimecardSummary;
